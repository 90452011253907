*{ 
  box-sizing: border-box;
}
body{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding-bottom: 5px;
}

table {
  width: 100%;
  margin: 3rem 0;
  font-size: 12px;
}

small{
  font-size: 0.65rem;
}

th {
  color:#fff;
  background-color: #00B5D1;
}
th, td {
padding: 0.4rem;
}
table, th, td {
border: 1px solid lightgray;
border-collapse: collapse;
}


.link{
  color:$middle-blue;
  text-decoration: none;
  cursor: pointer;
}

:any-link{
  @extend .link;
}

button:disabled{
    cursor: default;
}

.is-active{
    font-weight: bold;
}
.error{
    background-color: red;
    padding: 10px;
    color:white;
}

.success {  
  background-color: #d8f0d3;
  color:rgb(24, 72, 13);
  border: 1px solid #b1e1a6;
  padding: 1rem;
  margin: 1rem 0;
}
.warning {  
  background-color: #f6e3cc;
  color:#552f00;
  border: 1px solid #eec899;
  padding: 1rem;
  margin: 1rem 0;
}

// input:focus, textarea:focus { 
//   outline: none !important; border-color:$light-blue 
// }
input, select {
  outline-color: $light-blue;
  line-height: normal;
  border: 1px solid $input-border;
  padding: 12px;
  font-size: 18px;
  font-weight: 300;
  width:100%;
}

.padding{
  padding: 0 $padding;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;  
}


.fa-spin {
  animation: fa-spin 2s infinite linear;
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.loading{
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

button {
  cursor: pointer;
  background-color:$light-blue;
  border: none;
  color: white;
  padding: 8px 16px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.19);
  &:focus{
    outline:0;
  }

  &:disabled
  {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
}

.button-sm{
  padding: 5px 8px;
  font-size: 12px;

}

.button-unstyled {
  background-color:transparent;
  border: none;
  padding:0;
  color: initial;
  box-shadow: initial;
}

.saveButton {
  display: flex;
  align-items: center;  
}

.input-group{
  padding: 0 8px;
}

.form-item{
  margin-bottom: 25px;
  
  > label{
    display:block;
    margin-bottom: $form-item-label-field-spacing;
  }
  > div {
    margin-bottom: $form-item-label-field-spacing;
  }
}
.spaceBetween{
  display:flex;
  justify-content: space-between;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}


.horizontal{
  display: flex;
  align-items: center;
  > * {
    margin-right: 10px;
  }
}

.hand {
  cursor: pointer;
}

.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: $light-blue;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui > h1 {
  margin-top: 0;
}
.custom-ui > p {
  padding: 0 60px;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.blue-box {
  background-color:#00B5D1;
  padding: 1rem 0.2rem;
}
.flex-center{
  display: flex;
  align-items: center;
}

.system-settings {
  display: flex;
  justify-content: space-between;  
}
.system-settings > div {
  flex: 1;  
}