.stockPage {
    h1 {
        margin: 10px 0;
    }
    .sync{
        color: #6e3d00;
        background-color: #f6e3cc;
        border-color: #f3d8b8;
        padding: 10px;

        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
    }
}