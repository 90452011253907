.dashboard-row {
  display: flex;
  justify-content: space-between;
  align-items: center;  
  white-space: nowrap;
  border-top: 1px solid lightgray;
  padding: 14px 0;
  // > * {
  //   padding: 10px 0;
  // }

  

  &__ticker {
    margin-right: 10px;
    font-weight: 700;
    font-size: 32px;
  }
  &__website {
    font-size: 10px;
  }
  &__exchange-link {
    font-size: 10px;
  }
}

.dashboard-filter {  
  //margin: 4px 4px 4px 0px;
  display:flex;
  margin-top: 10px;

  &__text {
    margin-right: 10px;
  }
 
}