.tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    font-size: 14px;
  }
  
  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.4rem;
    cursor: pointer;

    @media screen and (min-width: 950px) {
      padding: 0.5rem 0.8rem;
    }
  }
  
  .tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
  }