//Colors
$dark-blue: #01133E;
$light-blue: #00B5D1;
$middle-blue: darken($light-blue, 20%);
$padding: 4px;

$input-border: #cacccd;
//Spacing
$form-item-label-field-spacing: 4px;
// $s-size:1.2rem;
// $m-size:1.6rem;
// $l-size:3.2rem;
// $desktop-breakpoint: 45rem;