.config {
    &__ticker-container {
      display:flex;      
    }
    &__exchange {
      margin-left: 1rem;
    }


    &__send-settings {
        display:flex;
        padding: 15px 5px;
        justify-content: space-between;
        border:1px solid $input-border;
    }

    &__send-settings-item {
        display: flex;
        align-items: center;
        > * {
          margin-right: 5px;
        }
      }
    &__button {
      font-size: 0.7rem;
      align-self: center;
      margin-left: 1.5rem;
    }
    &__changeTickerButton__confirming{
      color:white;
      background-color: red;
    }
    &__button__archive{
      color:white;
      background-color: red;
    }

    &__poll-container {
      display:flex; 
      justify-content: space-between;     
    }

    &__announcement-comment-template textarea {
      width:100%
    }
}