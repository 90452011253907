.investi-dark-blue {
  background-color: $dark-blue;
}

.header{
  background-color: $dark-blue;
  color:white;
  padding: 8px;
  

  &__settings{
    cursor: pointer;
  }

  ul{
    list-style-type:none;
    display: flex;
    flex-direction: column;    
    align-items: flex-end;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  li {
    padding: 20px 0;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  a {
    color:white;
  }

  &__menu {
    animation: slide-up 0.5s ease;
  }
  
}
.front-page-logo {
  width: 110.6px;
  height: 50px;
  
}

@keyframes slide-up {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}