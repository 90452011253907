.announcement-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  white-space: nowrap;
  border-bottom: 1px solid lightgray;
  padding: 14px 0;

    &__header {
        font-size: 12px;
        display:flex;
    }

    &__date{
        margin-right: 20px;
    }

  &__headline {
    white-space: initial;    
  }

  &__headlineOriginal {
    font-size: 0.8em;
    font-style: italic;
    white-space: initial;
  }

  &__sent {
    margin-right: 20px;
  }
  &__price-sensitive {
      color:red;
  }
  &__hits {
    margin-right: 20px;
  }
  &__menu-toggle{
      
      flex-direction: row-reverse;
      display: flex;
  }

  &__menu {
    margin-left: 5px;
    min-width: 75px;
      display: flex;
      flex-direction: column;

      button {          
        margin: 0.25rem 0;
          display: block;
      }
      button:first-child{
          align-self: flex-end;
      }
  }

  &__pdflink{
    margin-left: 10px;
  }

  &__deleted {
    background-color:red;
    color: #fff;
    padding: 0.25rem 1rem;
    margin-left: 1rem;
  }
  &__headline-text-deleted {
    text-decoration: line-through;
  }
}

.edit-announcement {
  &__header {
    display: flex;
    flex-direction: row-reverse;
  }
}

.announcement-actions-button {
  font-size: 12px;
  padding: 0.2rem 0.5rem;
  
}